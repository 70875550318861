

.slick-prev:before{
  color: #7955CF; 
  font-size: 25px; 
}

.slick-next:before{
  color: #7955CF; 
  font-size: 25px; 
}


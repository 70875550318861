.embla {
  max-width: 60rem;
  margin: auto;
  --slide-height: 40rem;
  --slide-spacing: .1rem;
  --slide-size: 25%;

}

@media (max-width: 600px) {
  .embla {
    --slide-size: 40%;
  }
}

/* @media (min-width: 600px) {
  .embla {
    --slide-size: 40%;
  }
} */

@media (min-width: 500px) and (max-width: 899px) {
  .embla {
    --slide-size: 30%;
  }
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * 0);
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  height: var(--slide-height);
  display: flex;
  align-items: center;
  justify-content: center;

  /* padding-left: var(--slide-spacing); */
}

.embla__slide__number {
  display: inline-block;
  position: relative;
  /* Ensure the container wraps the image */
  width: max-content;
  height: max-content;
}

.embla__slide__number img {
  display: block;
  /* Make sure image does not exceed container */
  max-width: 100%;
}

.embla__slide__number::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
  /* Optional: set a border radius to match the image if needed */
  border-radius: inherit;
  z-index: -1; /* Place shadow behind the image */
}

.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1rem;
  margin-top: -3rem;
}

.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.4rem;
  align-items: center;
}

.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}

.embla__button:disabled {
  color: var(--detail-high-contrast);
}

.embla__button__svg {
  width: 30%;
  height: 30%;
}

.embla__dots {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: -0.6rem;
}

.embla__dot {
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0.2rem;
  width: 1rem;
  height: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 1rem;
  height: 0.5rem;
  border-radius: 25%;
  background-color: rgb(180, 180, 180);
  content: '';
  transition: all 0.3s ease;
}

.embla__dot--selected:after {
  box-shadow: inset 1rem 0 0 0.2rem var(--text-body);
  width: 1rem;
  height: 0.4rem;
  background-color: #5e35b1;
  transition: all 0.3s ease;
}

.embla__slide__number {
  margin-top: -5rem;
  backface-visibility: hidden;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&family=Titan+One&display=swap');

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

/* .MuiPaper-root .MuiPaper-elevation .MuiPaper-rounded .MuiPaper-elevation8 .MuiPopover-paper .MuiMenu-paper .MuiMenu-paper  */
.css-1nnm5g0-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  max-height: 20rem !important;
}

.css-duqph6-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  background-color: rgb(198, 174, 255) !important;
  color : white,
}


  /* Style for each dot */
  .slick-dots li button:before {
    margin-top:  1rem;
    transition: all 0.3s ease;
    font-size: 15px;  /* Change the size of the dots */
    color: #7955CF;      /* Change the color of the dots */
  }
  
  /* Style for the active dot */
  .slick-dots li.slick-active button:before {
    color: #7955CF;      /* Change the color of the active dot */
    width : '20rem';
    transition: all 0.3s ease;
}
  